import React from 'react'
import styled, { css } from 'styled-components'
import { media } from '../../styles/media'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'


const StyledWrap = styled.div`
  margin:32px 16px;
  padding:16px 0px;
  border-top:2px #292929 solid;
  border-bottom:2px #292929 solid;
  display:flex;
  flex-flow:row wrap;
  align-items:center;
  justify-content:space-between;
  ${() => media.tablet(css`
    max-width:500px;
    margin:32px auto;
  `)}
  .soy{
    width:40%;
  }
  .text{
    display:flex;
    flex-flow:column nowrap;
    align-items:flex-end;
    justify-content:space-between;
    width:55%;
    p{
      font-weight:800;
      font-size:14px;
    }
    .gatsby-image-wrapper{
      width:50%;
    }
  }
`

const SoyGood = () => {
  const data = useStaticQuery(query)
  return (
    <StyledWrap>
      <StaticImage
        src='../../images/miniSoys.png'
        alt='大豆たち'
        placeholder='none'
        className='soy'
      />
      <div className='text'>
        <p>{data.text.tofuRefuse.imgtxt[0]}</p>
        <StaticImage
          src='../../images/logo-soygood.svg'
          alt='SoyGood by Francois'
          placeholder='none'
        />
      </div>
    </StyledWrap>
  )
}

export const query = graphql`
  {
    text: pagesYaml(pageName: {eq: "thirtyYearsPage"}) {
      tofuRefuse {
        imgtxt
      }
    }
  }
`

export default SoyGood
